import FetchService from './fetch.service';


class GTMServiceAPI{

    async GetAuthorizationLink (UserEmail) {
        const fetchService = new FetchService();
    
        const response = await fetchService("/authorize_user/", {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify({"user_email": UserEmail}),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });
    
        const respData = await response.json();
        return respData;
        
    };


    async GetAccounts (UserEmail) {
        const fetchService = new FetchService();
    
        const response = await fetchService("/acssess_GTM_data/", {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify({"accounts" : "", "user_email":UserEmail}),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });
    
        const respData = await response.json();
        return respData;
        
    };
    
    
    async GetContainers (accountId, UserEmail) {
        const fetchService = new FetchService();
    
        const response = await fetchService("/acssess_GTM_data/", {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify({"account_id" : accountId, "user_email":UserEmail}),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });
    
        const respData = await response.json();
        return respData;
        
    };


    async GetTags (containerId, selectedAccount, UserEmail) {
        const fetchService = new FetchService();
    
        const response = await fetchService("/acssess_GTM_data/", {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify({"container_id":containerId, "selected_account" : selectedAccount,  "user_email":UserEmail}),
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json',
            },
            credentials: 'include',
        });
    
        const respData = await response.json();
        return respData;
        
    };
}


export default new GTMServiceAPI();